import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import Event from '../views/events.vue'
import checkout from '../views/checkout.vue'
import orderHistory from '../views/orderHistory.vue'
import TransactionHistory from '../views/TransactionHistory.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      appFrame: false,
      transitionName: 'fade',
      navBar: false,
      basePath: true,
    },
    component: Home
  },
  {
    path: '/event',
    name: 'event',
    meta: {
      appFrame: false,
      transitionName: 'fade',
      navBar: false,
      basePath: true,
    },
    component: Event
  },
  {
    path: '/checkout',
    name: 'checkout',
    meta: {
      appFrame: false,
      transitionName: 'fade',
      navBar: false,
      basePath: true,
    },
    component: checkout
  },
  {
    path: '/history',
    name: 'orderHistory',
    meta: {
      appFrame: false,
      transitionName: 'fade',
      // transitionName: 'zoomIn-SlideOut',
      navBar: false,
      basePath: true,
    },
    component: orderHistory
  },
  {
    path: '/transactions',
    name: 'Transactions',
    meta: {
      appFrame: false,
      transitionName: 'fade',
      // transitionName: 'zoomIn-SlideOut',
      navBar: false,
      basePath: true,
    },
    component: TransactionHistory
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
