<template>
  <v-row class="form-conat pa-0 ma-0">
    <v-col cols="12" class="pa-0 ma-0">
      <label v-if="phone" class="text-caption">Celular</label>

      <v-text-field
        v-model="phone"
        required
        @keydown="preventNonNumeric"
        @input="validatePhone"
        type="tel"
        class="rounded-lg"
        filled
        full-width
        rounded
        :maxlength="maxDigits"
        :error-messages="errorMessage"
        :prefix="`+${Country.callingCodes[0]}`"
      >
        <template v-slot:prepend-inner>
          <div class="wrap-flag">
            <v-menu v-if="Country" offset-y transition="slide-x-transition" left bottom rounded="lg" right>
              <template v-slot:activator="{ on, attrs }">
                <div class="flag rounded-lg" v-bind="attrs" v-on="on">
                  <img :src="`${require(`@/Countries/` + Country.flag)}`" alt="" />
                  <v-icon>mdi-menu-down</v-icon>
                </div>
              </template>
              <v-list height="250px" class="list" v-if="Countries">
                <v-list-item
                  v-for="(item, index) in Countries"
                  :key="index"
                  class="list-item"
                  @click="setCountry(item)"
                >
                  <div class="img-text">
                    <img :src="`${require(`@/Countries/` + item.flag)}`" alt="" />
                    <p class="ml-2"><b>{{ item.name }} </b> +{{ item.callingCodes[0] }}</p>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

---

## **📌 Script Mejorado**
```vue
<script>
import countries from "../Countries/countries.json";
import country from "../Countries/country.json";

export default {
  name: "inputPhone",
  props: ["value"],
  data() {
    return {
      Countries: countries,
      Country: country,
      phone: this.value || "",
      errorMessage: "",
      maxDigits: 15, // Máximo permitido (por defecto)
      phoneLengths: {
        "HN": 8,  // Honduras
        "SV": 8,  // El Salvador
        "CL": 8,  // Chile
        "PE": 9,  // Perú
        "BR": 9,  // Brasil
        "ES": 9,  // España
        "MX": 10, // México
        "US": 10, // Estados Unidos
        "IN": 10  // India
      }
    };
  },
  methods: {
    validatePhone() {
      const limit = this.phoneLengths[this.Country.alpha2Code] || 15;

      // Remueve caracteres no numéricos y aplica el límite de dígitos
      this.phone = this.phone.replace(/\D/g, "").slice(0, limit);

      if (!this.phone) {
        this.errorMessage = "El número de teléfono es requerido";
        this.$emit("retunValue", "", this.Country);
        return;
      }

      if (this.phone.length < 8) {
        this.errorMessage = "Debe tener entre 8 y " + limit + " dígitos";
        this.$emit("retunValue", "", this.Country);
        return;
      }

      this.errorMessage = "";
      this.$emit("retunValue", this.phone, this.Country);
    },

    preventNonNumeric(event) {
      // Permitir solo números del 0 al 9 y teclas de control como backspace, enter, etc.
      if (!/^\d$/.test(event.key) && !["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Enter"].includes(event.key)) {
        event.preventDefault();
      }
    },

    setCountry(selectedCountry) {
      this.Country = selectedCountry;
      this.maxDigits = this.phoneLengths[this.Country.alpha2Code] || 15; // Ajustar límite de dígitos según el país
      this.validatePhone(); // Validar el teléfono después de cambiar el país
    }
  },
  watch: {
    value(newValue) {
      this.phone = newValue;
      this.validatePhone();
    },
  },
  mounted() {
    if (this.value) {
      this.phone = this.value;
    }
  },
};
</script>
<style lang="scss" >
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
    margin-right: auto;
    padding-right: 4px;
    margin-top: 10px !important;
}
.wrap-flag {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;


  .flag {
    height: 36px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40px;
      height: 30px;
      border-radius: 8px;
    }
    .select-flag {
      height: 250px;
      width: 100px;
      left: 0;
      top: 0;
      overflow-y: scroll;
      position: absolute;
      background-color: #f2f2f2;
      z-index: 100;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: rgba(0, 0, 0, 0.435) 2px 2px 20px;
    }
  }
}
.list {
  background-color: #f2f2f2;
  overflow: scroll;
  border-radius: 20px;
}

// @include responsive(desktop) {
//  font-size: 20px;
//  //... all what you want
// }
ul {
  padding-left: 5px !important;
  li {
    list-style: none;
  }
}
.list {
  background-color: #f2f2f2;
  overflow: scroll;
  border-radius: 20px;
}
.img-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 30px;
    height: 23px;
    border-radius: 5px;
  }
}
.form-conat {
  width: 100%;
}
.flex-start {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
    margin-right: auto;
    padding-right: 4px;
    margin-top: 10px !important;
}

</style>
