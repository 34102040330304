const cors = require("cors")({ origin: true });

import axios from 'axios';

export const createConsult = async (data) => {
  try {
    const url = 'https://us-central1-delivery-dev-64a57.cloudfunctions.net/httpEvertecCreateSession';
    
    const headers = {
      'Content-Type': 'application/json',
    };
 
    const response = await axios.post(url, data, { headers });
    
    return response.data;
  } catch (error) {
    
    console.log('Error al crear sesión:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const ConsultSession = async (requestId) => {
  console.log('entrandooooo******** ConsultSession',requestId);
///let  apiUrl= process.env.VUE.URL_CONSULT; 
  // let  apiUrl='https://us-central1-delivery-dev-64a57.cloudfunctions.net/httpEvertecConsultSession'
  let  apiUrl ='https://us-central1-el-ocho.cloudfunctions.net/httpEvertecConsultSession'
  console.log('apiiiiUrl',apiUrl)

  console.log('requestRecbido en consult seson',requestId)
  try {
      const url =apiUrl
    

    
    const headers = {
      'Content-Type': 'application/json',
    };
    
 const body = {
  requestId
}
    const response = await axios.post(url, body, { headers });
    console.log('Respuesta del servidor:', response.data);
    return response.data;
  } catch (error) {
    console.log('error******',error);
    console.log('Error al crear sesión:', error.response ? error.response.data : error.message);
    throw error;
  }
};
  