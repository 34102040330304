<template>
  <v-container class="mt-12 mb-12">
    <v-card v-if="loading" class="mt-12">
      <v-row class="mt-4">
        <v-col cols="12" md="6" lg="6" sm="6">
          <v-row class="px-4 mt-4 stiky">
            <v-col cols="12" v-for="(ticket, i) in orderRespal" :key="i">
              <ticket-checkout :fromCheckout="true" @Calcu="calcuQuantity" :tickets="ticket" @deleted="deletedSelect" />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6" lg="6" sm="6">
          <!-- <v-alert
            v-if="timer"
            dense
            border="bottom"
            :color="colorAlert"
            dark
            class="text-center"
          >
            <span class="font-weight-black" >  {{ timer }} </span>
          </v-alert> -->

          <v-card-title v-if="business && event">
            <v-avatar>
              <v-img :src="business.logo.original" :lazy-src="business.logo.loading">
              </v-img>
            </v-avatar>
            <span class="ml-2">{{ business.shortName }}</span>
            <v-spacer></v-spacer>
            <v-chip v-if="timer" class="px-4 py-4" :color="colorAlert">
              <v-icon small class="mr-2"> fa fa-clock </v-icon>
              <span class="font-weight-black"> {{ timer }} </span>
            </v-chip>
          </v-card-title>

          <span v-if="event" style="font-size: 18px" class="ml-4 text-center font-weight-bold mb-2">{{ event.name
            }}</span>

          <v-form class="px-4 mt-2" ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="6">
                <label for="" class="text-caption">Nombre</label>
                <v-text-field style="border-radius: 10px" v-model="user.name" required readonly filled hide-details
                  rounded></v-text-field>
              </v-col>
              <v-col cols="6">
                <label for="" class="text-caption">Apellido</label>
                <v-text-field style="border-radius: 10px" v-model="user.surname" required readonly filled hide-details
                  rounded></v-text-field>
              </v-col>
            </v-row>

            <inputPhone :code="codeArea" :value="phoneUser" />
            <label for="" class="text-caption">Correo</label>
            <v-text-field style="border-radius: 10px" v-model="user.email"
              messages="Opcional (Recibir tu comprobante de tu orden)" required readonly filled rounded></v-text-field>
            <v-row v-if="event.customForm?.active" class="mt-1">
              <v-col v-for="(inputs, i) in event.customForm.customFields" :key="i" :cols="inputs.size"
                class="my-0 py-0">
                <label for="" class="text-caption">{{ inputs.label }}</label>

                <v-select v-if="inputs.type == 'select'" :items="inputs.items"
                  :rules="inputs.required ? [(v) => !!v || 'Requerido'] : []" filled style="border-radius: 10px" rounded
                  required v-model="inputs.value"></v-select>
                <v-text-field v-if="inputs.type == 'text'" style="border-radius: 10px" v-model="inputs.value"
                  :type="inputs.type" :rules="inputs.required ? [(v) => !!v || 'Requerido'] : []" required filled
                  rounded></v-text-field>
                <v-text-field v-if="inputs.type == 'tel'" style="border-radius: 10px" v-model="inputs.value"
                  :required="inputs.required" :type="inputs.type" @keydown="isNumber($event)"
                  :rules="inputs.required ? [(v) => !!v || 'Requerido'] : []" filled hide-details
                  rounded></v-text-field>
                <v-switch v-if="inputs.type == 'switch'" dense :label="inputs.label"
                  v-model="inputs.required"></v-switch>
              </v-col>
            </v-row>
          </v-form>

          <div class="checkout-price px-4">
            <span class="text-caption text-center">Sobre mi orden</span>
            <v-divider></v-divider>

            <div class="row-label text-caption">
              <span>SubTotal</span>
              <span> {{ subtotal | currency }}</span>
            </div>
            <div class="row-label text-caption" v-for="(i, index) in extrafeeArray" :key="index">
              <span v-if="i.active">{{ i.extraFeeName }}</span>
              <span v-if="i.active">
                <b>{{ i.amountFront | currency }}</b>
              </span>
            </div>
            <div class="row-label">
              <span><b>Total de la orden</b></span>
              <span style="color: #FF1744" class="total">
                {{ total | currency }}</span>
            </div>
            <v-divider></v-divider>
            <a href="https://firebasestorage.googleapis.com/v0/b/el-ocho.appspot.com/o/dilovive%2FINT-A-002%20FAQ%20PAGOS%20ELECTRO%CC%81NICOS%20-%20PLACETOPAY.pdf?alt=media&token=d086cfcb-9a0e-464e-bfda-d112fe862516"
              target="_blank" class="text-primary text-decoration-none">
              ¿Tienes dudas? Haz clic aquí para consultar nuestras Preguntas Frecuentes.
            </a>

            <v-btn color="primary" :loading="preloader" height="50px" class="mt-4" @click="preOrder">
              Finalizar compra
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="dialogDeleted" v-if="dialogDeleted" max-width="400px">
      <v-card>
        <v-card-title>
          <h3 style="color: #fff" class="text-center">
            Eliminar
          </h3>
        </v-card-title>
        <v-card-text class="text-center">
          <h3 style="color: #fff">
            ¿Deseas eliminar {{ select.quantity }}x {{ select.name }}
          </h3>
        </v-card-text>
        <v-card-actions class="flex-end">
          <v-btn text @click="dialogDeleted = false"> cancelar </v-btn>
          <v-btn color="primary" @click="deleted"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialogo dilo pay  -->
    <v-dialog style="
        border-radius: 10px !important;
        z-index: 900;
        border: none !important;
      " v-model="dialogPaymentOnline" v-if="dialogPaymentOnline" max-width="500px" hide-overlay :fullscreen="mobile"
      scrollable light transition="dialog-bottom-transition" persistent>
      <v-card>
        <v-card-title color="#FF1744" style="background-color: #FF1744">
          <v-btn @click="cancelPayOnline" absolute icon right dark>
            <v-icon color="#fff"> mdi-close</v-icon>
          </v-btn>

          <img src="@/assets/logoPay.svg" height="30px" class="mb-2" alt="" srcset="" />

          <v-spacer></v-spacer>
        </v-card-title>

        <!-- <v-card-text class="pa-0">
          <iframe class="iframe-pay" sandbox="allow-scripts allow-same-origin allow-forms allow-popups" :src="`${dataIframe}?radius=10&btncolor=${color.slice(
            1
          )}&colorprimary=${color.slice(1)}&seed=${seed}&token=${token}`" frameborder="0"></iframe>
        </v-card-text> -->
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTimer" v-if="timer" max-width="400">
      <v-card>
        <v-card-title class="text-center py-12 px-10">
          <span>
            El tiempo para la compra es de
            <v-chip v-if="timer" class="px-4 py-2" :color="colorAlert">
              <span style="font-size: 20px" class="font-weight-black">
                {{ timer }}
              </span>
            </v-chip>
            minutos</span>
        </v-card-title>
        <v-divider> </v-divider>
        <v-card-actions class="flex-center">
          <v-btn block color="primary" @click="dialogTimer = false">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- //modal succes -->

    <v-dialog v-model="dialogOrderCompleted" max-width="400px" persistent>
      <v-card class="text-center">
        <v-card-title class="headline d-flex justify-center">
          🛒 {{ dialogTitle }}
        </v-card-title>
        <v-card-text class="text-center">
          <p>{{ dialogMessageText }}</p>
        </v-card-text>

        <v-card-text class="text-center">
          <p><strong>ID Transacción:</strong> {{ transactionId }}</p>
          <p><strong>Monto:</strong> {{ this.monto }}</p>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" @click="closeDialog">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import moment from "moment-timezone";
import { nanoid } from "nanoid";
import "moment/locale/es";
moment.locale("es");
import { db, fb } from "@/firebase";
import inputPhone from "../components/inputPhone.vue";
import { mapActions, mapState } from "vuex";
import Tickets from "../components/tickets";
import TicketCheckout from "../components/ticketCheckout.vue";
import { createConsult, ConsultSession } from "../services/firebaseService";
export default {
  name: "checkout",
  components: {
    inputPhone,
    Tickets,
    TicketCheckout,
  },
  mounted() {
    console.log('loadingTicketssss')
  this.fetchTickets(); 
},
  data() {
    return {
      dialogMessage: false,
      dialogTitle: "",
      dialogMessageText: "",
      requestId: null,
      dialogOrderCompleted: false,
      transactionId: null,
      monto: null,
      valid: true,
      dialogTimer: true,
      preloader: false,
      dataIframe: null,
      dialogPaymentOnline: false,
      mobile: screen.width <= 550 ? true : false,
      select: null,
      dialogDeleted: false,
      loading: false,
      business: null,
      paymentGateways: {},
      color: "#FF1744",
      extrafee: [],
      timer: null,
      colorAlert: "error",
      hours: 0,
      seed: null,
      validateRouter: true,
      token: null,
      days: 0,
      minutes: 0,
      intervalTimer: null,
      seconds: 0,
      datend: "2023-12-30T00:01",
      noactive: true,
      event: [],
      required: [(v) => !!v || "Requerido"],
      processUrl: null
    };
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
  },
  computed: {
    ...mapState([
      "selectEvent",
      "user",
      "orderRespal",
      "phoneUser",
      "SessionTimer",
      "sessionToken",
      "codeArea",
    ]),
    paymentGTW() {
      return this.paymentGateways[0];
    },
    subtotal() {
      var suma = 0;
      this.orderRespal.forEach((element) => {
        suma = suma + element.price * element.quantity;
      });
      return suma;
    },
    extrafeeArray() {
      return this.event.extraFees.map((item) => {
        item.amountFront = this.extraFeeCal(item);
        return item;
      });
    },
    total() {
      var total = 0;
      var accExtraFee = 0;
      if (this.event.extraFees.length > 0) {
        this.event.extraFees.forEach((fee) => {
          accExtraFee += this.extraFeeCal(fee);
        });
        total = accExtraFee + this.subtotal;
        // console.debug(total);
      } else {
        total = this.subtotal;
      }
      return total;
    },
  },
  methods: {
    ...mapActions([
      "_selectEvent",
      "Alert_",
      "_orderRespal",
      "_Order",
      "_timerSession",
    ]),
    async fetchTickets() {
    this.loading = true;

    try {
      // 🚀 Si `orderRespal` viene de Vuex, recárgalo
      this._orderRespal(await db.collection("orders").where("userId", "==", this.user.uid).get());
      
      console.log("Tickets recargados:", this.orderRespal);
    } catch (error) {
      console.error("Error al cargar los tickets:", error);
    }

    this.loading = false;
  },
    showDialog(title, message) {
      this.dialogTitle = title;
      this.dialogMessageText = message;
      this.dialogMessage = true;
    },
    async handleApprovedTransaction(Response, paymentReference) {
      console.log("✅ Transacción aprobada");
      this.preloader = true;

      if (Response.data.createOrderResult.paymentReference) {
        db.collection("pendingPaymentRequest")
          .doc(paymentReference)
          .onSnapshot((doc) => {
            console.log("data paid", doc.data()?.status);
            if (doc.data()?.status === "paid") {
              console.log("✔ Orden pagada correctamente");
              this.validateRouter = false;
              this.dataIframe = null;
              this.dialogPaymentOnline = false;
            }
          });
      }
      this.showDialog("🎉 Orden Completada", "Tu orden ha sido procesada exitosamente.");
      console.log("Mostrando modal de orden completada...");
      this.dialogOrderCompleted = true;

    },
    showAlert(message, type) {
      console.log("⚠ Mostrando alerta:", message);
      this.Alert_({
        text: message,
        timeout: 2000,
        btn_closed: true,
        icon: false,
        iconType: "mdi-check",
        type: type,
      });
      this.preloader = false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    closeDialog() {
      this.dialogOrderCompleted = false;
      this.$router.push("/history");
    },
    deletedSelect(item) {
      this.select = item;
      this.dialogDeleted = true;
    },
    calcuQuantity() { },
    AddMinutesToDate(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    },
    deleted() {
      this.select;
      var order = Object.assign([], this.orderRespal);
      var index = order.indexOf(this.select);
      this.dialogDeleted = false;
      this.select = null;
      // console.debug(order);
      // console.debug(index);

      order.splice(index, 1);
      this._orderRespal(order);
    },
    extraFeeCal(fee) {
      let acc = 0;
      if (!fee.extraFeePerTicket) {
        let subtotal = this.orderRespal.reduce((accumulate, currentValue) => {
          currentValue.isFree
            ? 0
            : (accumulate =
              accumulate + currentValue.quantity * currentValue.price);

          return accumulate;
        }, 0);
        // console.log(subtotal);
        if (fee.active) {
          if (fee.extraFeeIsFixed) {
            acc = subtotal == 0 ? 0 : fee.extraFeeSum;
          } else {
            acc = acc + subtotal * (fee.extraFeeSum / 100);
          }
        }
        // console.debug(acc);
      } else {
        this.orderRespal.forEach((ticket) => {
          if (ticket.isFree) {
            acc = acc + 0;
          } else {
            if (fee.active) {
              if (fee.extraFeeIsFixed) {
                acc = acc + fee.extraFeeSum * ticket.quantity;
              } else {
                acc =
                  acc +
                  (fee.extraFeeSum / 100) * (ticket.quantity * ticket.price);
              }
            }
          }
        });
      }
      return acc;
    },
    updateCounter() {
      var timerStore = new Date(this.SessionTimer * 1000);
      var now = new Date();
      var end = this.AddMinutesToDate(timerStore, 10);
      var distance = end - now;
      if (distance > 0) {
        if (distance < 90000) {
          this.colorAlert = "error";
        }
        this.timer = moment(distance).format("mm:ss");
      } else {
        clearInterval(this.intervalTimer);
        // console.debug("clearInterval");
        this.$router.push("/event");
      }
    },
    async preOrder() {

      // await this.handleCreatetSession(this.user)
      // var processUrl = this.processUrl;
      // P.init(processUrl);
      this.seed = null;
      this.preloader = true;
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        var orderTickets = this.orderRespal.map((t) => {
          var obj = {
            ticketId: t[`.key`],
            amount: t.quantity,
          };
          return obj;
        });

        this.seed = nanoid(40);
        console.log('informacion de el usuario', this.user)
        var data = {
          seed: this.seed,
          session: this.sessionToken,
          tickets: orderTickets,
          categoryId: this.event.categories[0],
          payment: this.paymentGTW[`.key`],
          paymentToken: "",
          customFields: this.event.customForm?.active ? this.event.customForm.customFields : [],
          city: this.event.city,
          userInfo: this.user
        };
        this.monto = this.paymentGTW[`.key`];
        console.log('data send vivesell', JSON.stringify(data));
        let requestInformation;
        console.log("Usuario autenticado:", fb.auth().currentUser);
        var httpOviveSellEvertec = fb.functions().httpsCallable("httpOviveSellEvertec");
        httpOviveSellEvertec(data)
          .then((Response) => {
            console.debug("Response", JSON.stringify(Response));
            this.token = Response.data.token;
            this.requestId = Response.data.responseEvertec.requestId;

            if (this.total != 0) {
              //this.dataIframe = Response.data.redirectUrl;
              var paymentReference =
                Response.data.createOrderResult.paymentReference;
              var processUrl = Response.data.redirectUrl;
              P.init(processUrl);

              P.on('response', async (response) => {
                console.log('response success');
                // requestInformation = await ConsultSession(this.requestId);
              });
              P.on("close", async () => {
                try {
                  this.preloader = true;
                  
                  console.log("El usuario cerró el Lightbox", this.requestId);

                  let requestInformation = await ConsultSession(this.requestId);
                  console.log("requestInformation",requestInformation);
                  // 🔥 Obtener estado de la transacción
                  const transactionStatus = requestInformation.status?.status;
                  const transactionId = Response.data.responseEvertec.transactionId;

                  console.log("Estado de la transacción:", transactionStatus);

                  switch (transactionStatus) {
                    case "APPROVED":
                      await this.handleApprovedTransaction(Response, paymentReference);
                      break;

                    case "PENDING":
                      this.showDialog("⚠️ Transacción Pendiente", "La transacción está en estado pendiente.");
                      this.dialogOrderCompleted = true;
                      this.$router.push("/transactions");
                      break;

                    case "REJECTED":
                      this.dialogOrderCompleted = true;
                      this.showDialog("❌ Transacción Fallida", "La transacción no se pudo completar, intenta nuevamente.");
                      this.$router.push("/transactions");
                      break;

                    default:
                      console.warn("Estado desconocido:", transactionStatus);
                  }

                  this.transactionId = transactionId;
                  this.monto = this.total;
                  this.preloader = false;
                } catch (error) {
                  console.error("Error al cerrar el Lightbox:", error);
                  this.preloader = false;
                }
              });

              //this.dialogPaymentOnline = true;


              if (Response.data.createOrderResult.paymentReference) {
                db.collection("pendingPaymentRequest")
                  .doc(paymentReference)
                  .onSnapshot((doc) => {
                    this.validateRouter = false;
                    
                    if (doc.data().status == "paid") {
                     
                      //this.dataIframe = null;
                      this.dialogPaymentOnline = false;
                      this.Alert_({
                        text: "Orden completada",
                        timeout: 2000,
                        btn_closed: true,
                        icon: false,
                        iconType: "mdi-check",
                        type: "success",
                      });
                      this._orderRespal([]);
                      this.closedSuccess(doc.data().orders);
                      this.$router.push("/history");

                      // }, 1000);
                    }
                    const iframe = document.getElementById('placetopay_');
            

                  });
              }
            } else {
              this.Alert_({
                text: "Orden completada",
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "success",
              });
              this.validateRouter = false;
              this._orderRespal([]);
              this.closedSuccess(true);
              this.$router.push("/history");
            }

            // console.debug(Response);

            this.preloader = false;
          })
          .catch((error) => {
            this.Alert_({
              text: error.message,
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
            this.preloader = false;
          });
      } else {
        this.Alert_({
          text: "Completa los campos solicitados 😬",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
        this.preloader = false;
      }
    },
    cancelPayOnline() {
      this.dialogPaymentOnline = false;
      this.preloader = false;
    },
    closedSuccess(order) {
      // console.debug(order);
    },
    async handleCreatetSession(user) {

      const payload = {
        name: user.name,
        lastName: user.surname,
        phone: user.phone,
        email: user.email,
        totalPayment: this.total,
        userId: user.uid
      }

      try {
        const response = await createConsult(payload);
        this.processUrl = response.processUrl;
        console.log('Sesión creada con éxito:', response.processUrl);
      } catch (error) {
        console.error('Error al crear la sesión:', error);
      }



    },
  },
  watch: {
    orderRespal(e) {
      if (e.length > 0) {
      } else {
        this.$router.push("/event");
      }
    },
  },
  mounted() {
    const iframe = document.getElementById("placetopay_");
    console.log('userrr*****', this.user)


    if (iframe) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
            if (iframe.style.display === 'none' || iframe.style.visibility === 'hidden') {
              console.log('El iframe fue cerrado u ocultado');
              observer.disconnect();
              this.$router.push("/history");
            }
          }
        });
      });

      observer.observe(iframe, { attributes: true }); // Observa cambios en los atributos del iframe
    } else {
      console.error('El iframe no fue encontrado.');
    }
    this.$nextTick(async () => {
      if (this.orderRespal.length == 0) {
        this.$router.push("/");
      }

      console.debug(window);
      this.loading = false;

      await this.$binding(
        "business",
        db.collection("businesses").doc(this.selectEvent.business)
      );
      await this.$binding(
        "event",
        db.collection("events").doc(this.selectEvent[`.key`])
      );
      await this.$binding(
        "paymentGateways",
        db.collection("paymentGateways").where("name", "==", "ochoPay")
      );
      // console.debug(this.extrafeeArray);
      this.intervalTimer = setInterval(this.updateCounter, 1000);
      this.loading = true;
    });
  },
  destroyed() {
    if (this.validateRouter) {
      console.debug("Unblock");
      this._orderRespal([]);
      setTimeout(() => {
        var data = {
          session: this.sessionToken,
        };
        var httpOviveUnblockTickets = fb
          .functions()
          .httpsCallable("httpOviveUnblockTickets");
        httpOviveUnblockTickets(data)
          .then((Response) => {
            // console.debug(Response);
            clearInterval(this.intervalTimer);
            // console.debug("clearInterval");
          })
          .catch((error) => {
            // console.debug(error);
          });
      }, 1000);
    }
  },
};
</script>
<style lang="scss" scoped>
.checkout-price {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;

  .row-label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.total {
  font-size: 1.5em;
  font-weight: 900;
}

.iframe-pay {
  width: 100% !important;
  height: 100vh;
}

.v-dialog__content--active {
  z-index: 1000 !important;
}

.stiky {
  position: sticky;
  top: 80px;
}
</style>