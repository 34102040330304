<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="400" persistent>
      <!-- TODO: arreglar esto. -->
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" small v-on="on">
          Inicia sesión
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title>
          Ingresa tu # de celular
          <v-btn
            icon
            absolute
            right
            top
            color="primary"
            @click="dialog = false"
          >
            <v-icon small> fa fa-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="text-center flex-center pt-5 px-4">
          <inputPhone @retunValue="valueInput" @actions="onNext" />
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="primary my-2"
            width="50%"
            height="50px"
            :loading="loading"
            @click="onNext"
            type="button"
            block
            :disabled="!isValidPhone"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-if="otpDialog" v-model="otpDialog" max-width="500px">
      <otp
        :auth="authObject"
        ref="code"
        @cancel="(otpDialog = false), (loading = false)"
        @success="otpConfirmed"
      />
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import inputPhone from "./inputPhone";
import otp from "./settings/insertCode.vue";
import { mapActions, mapState } from "vuex";
import countries from "../Countries/countries.json";
import country from "../Countries/country.json";

export default {
  components: { inputPhone, otp },
  name: "preLogin",

  data() {
    return {
      isValidPhone: false,
      Countries: countries,
      Country: country,
      valid: true,
      dialog: false,
      otpDialog: false,
      authObject: null,
      verificationId: null,
      phone: null,
      code: null,
      loading: false,
    };
  },

  methods: {
    ...mapActions(["Alert_", "setPhone", "_codeArea"]),

    valueInput(e, code) {
      this.phone = e;
      this.code = code;
      this.validatePhone();
    },

    validatePhone() {
      this.isValidPhone = /^\d{8,15}$/.test(this.phone);
    },

    onNext() {
      this.loading = true;
      if (this.isValidPhone) {
        this.mfaLogin();
      } else {
        this.loading = false;
        this.Alert_({
          text: "Ingrese un número de 8 dígitos.",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
      }
    },

    mfaLogin() {
      var phone = `${this.code.callingCodes[0]}${this.phone}`;
      var datos = { k: phone };

      var callableGenerateSmsTokenCall = fb.functions().httpsCallable("callableGenerateSmsTokenCall");

      callableGenerateSmsTokenCall(datos).then((response) => {
        this.otpDialog = true;
      });
    },

    otpConfirmed(otp) {
      this.otpDialog = false;
      var phone = `${this.code.callingCodes[0]}${this.phone}`;
      var datos = { k: phone, t: otp };

      var callableValidateToken = fb.functions().httpsCallable("callableValidateToken");

      callableValidateToken(datos)
        .then((response) => {
          this.setPhone(this.phone);
          this._codeArea(this.code);
          this.singInToken(response.data.token);
        })
        .catch((error) => {
          this.Alert_({
            text: error.message,
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "error",
          });
          this.otpDialog = true;
        });
    },
  },
};
</script>
