<template>
  <v-dialog v-model="visible" max-width="80%">
    <v-card>
      <v-card-title>
        <span class="headline">Terms and conditions</span>
      </v-card-title>

      <v-card-text>
        <iframe
          v-if="pdfUrl"
          :src="sanitizedUrl"
          width="100%"
          height="600px"
          style="border: none;"
        ></iframe>
        <p v-else>No hay PDF para mostrar</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn  class="mb-4" color="green" @click="onAceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    pdfUrl: {
      type: String,
      required: true,
    },
    onAceptar: {
      type: Function,
      required: true, // 🔥 Se espera que esta función venga del padre
    },
  },
  computed: {
    sanitizedUrl() {
      return `${this.pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`;
    },
  },
  data() {
    return {
      visible: true,
    };
  },
};
</script>