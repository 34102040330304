<template>
  <v-container class="mt-12 mb-12">
    <h2 style="color: #fff">Mis Transacciones</h2>

    <!-- 🔥 Loader igual al de la otra sección -->
    <v-card v-if="loading" class="mt-12">
      <v-row class="mt-4">
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate color="primary" size="50"></v-progress-circular>
          <p>Cargando transacciones...</p>
        </v-col>
      </v-row>
    </v-card>

    <div v-else-if="transactions.length > 0">
      <v-card class="mt-2">
        <v-card-text>
          <v-data-table :headers="headers" :items="transactions" :items-per-page="options.itemsPerPage"
            :page.sync="options.page" hide-default-footer class="elevation-0">
            <template v-slot:[`item.statusTransaction`]="{ item }">
              <v-chip :color="getStatusColor(item.statusTransaction)" small>
                <span>{{ item.statusTransaction }}</span>
              </v-chip>
            </template>

            <!-- Formato de la fecha -->
            <template v-slot:[`item.creationDate`]="{ item }">
              {{ item.creationDate | dateFormat }}
            </template>

            <!-- Monto total -->
            <template v-slot:[`item.totalPayment`]="{ item }">
              {{ item.totalPayment | currency }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn color="primary" @click="viewTransactionDetails(item)" small>
                Ver Detalles
              </v-btn>
            </template>
          </v-data-table>
          <v-pagination v-if="transactions.length > options.itemsPerPage" v-model="options.page"
            :length="Math.ceil(transactions.length / options.itemsPerPage)" color="primary" class="mt-4"></v-pagination>
        </v-card-text>
      </v-card>
    </div>

    <h1 v-else class="text-center" style="color: #fff">
      😬 No tienes transacciones registradas
    </h1>

    <!-- Diálogo para ver detalles de la transacción -->
    <v-dialog v-model="dialogTransaction" max-width="500">
      <v-card>
        <v-card-title>Detalles de la Transacción</v-card-title>
        <v-card-text>
          <p><strong>ID:</strong> {{ selectedTransaction.transactionId }}</p>
          <p><strong>Estado:</strong> {{ selectedTransaction.statusTransaction }}</p>
          <p><strong>Monto:</strong> {{ selectedTransaction.totalPayment | currency }}</p>
          <p><strong>Fecha:</strong> {{ selectedTransaction.creationDate | dateFormat }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="dialogTransaction = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import { mapState } from "vuex";

moment.locale("es");

export default {
  name: "TransactionHistory",
  data() {
    return {

      loading: true, // 🔥 Inicia con el loader activado
      transactions: [],
      dialogTransaction: false,
      selectedTransaction: {},
      options: {
        page: 1,
        itemsPerPage: 15
      },
      headers: [
        { text: "ID Transacción", value: "transactionId" },
        { text: "Estado", value: "statusTransaction" },
        { text: "Monto", value: "totalPayment" },
        { text: "Fecha", value: "creationDate" },
        { text: "Acción", value: "actions", width: "150px" }
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  filters: {
    dateFormat(timestamp) {
      if (!timestamp || typeof timestamp !== "object") return "Fecha no disponible";
      const date = new Date(timestamp._seconds * 1000);
      return moment(date).format("DD/MM/YY - hh:mm a");
    },
    currency(amount) {
      return `L ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(amount)}`;
    },
  },
  methods: {
    viewTransactionDetails(item) {
      console.log("📌 Ver detalles de transacción:", item);
      this.selectedTransaction = item;
      this.dialogTransaction = true;
    },
    getStatusColor(status) {
      if (status === "APPROVED") return "green";
      if (status === "REJECTED") return "red";
      if (status === "PENDING") return "orange";
      return "gray";
    },
    async fetchTransactions() {
      try {
        if (!this.user || !this.user[".key"]) {
          console.warn("⚠️ Usuario no definido. No se pueden obtener transacciones.");
          this.loading = false;
          return;
        }

        console.log("📌 Ejecutando fetchUserTransactions...");

        const getUserTransactions = fb.functions().httpsCallable("httpEvertecGetUserTransactions");

        const response = await getUserTransactions({ userId: this.user[".key"] });

        console.log("📌 Transacciones obtenidas:", response.data.transactions);

        this.transactions = response.data.transactions.map(transaction => ({
          transactionId: transaction.transactionId,
          statusTransaction: this.getTransactionStatus(transaction),
          totalPayment: transaction.totalPayment || 0,
          creationDate: transaction.creationDate,
        }));

      } catch (error) {
        console.error("❌ Error obteniendo transacciones:", error);
      } finally {
        this.loading = false; // 🔥 Apagar el loader después de la carga
      }
    },
    getTransactionStatus(transaction) {
      if (transaction.statusConsultSession) {
        if (transaction.statusConsultSession === "APPROVED") return "APPROVED";
        if (transaction.statusConsultSession === "REJECTED") return "REJECTED";
        if (transaction.statusConsultSession === "PENDING") return "PENDING";
      }
      // if (transaction.reasonConsultSession === "00") return "APPROVED";
      // return "---";
    },
  },
  mounted() {
    console.log("📌 Usuario cargado:", this.user);
    this.fetchTransactions();
  }
};
</script>

<style lang="scss">
@import "@/_responsive.scss";

.control-city {
  padding: 10px;
  background-color: #20172b;
  border-radius: 15px;
}

.stiky-b {
  position: sticky;
  top: 32px;
  z-index: 100;
}

.info-text-Card {
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  background-color: #20172b;
  border-radius: 10px;
  font-weight: 400;
}
</style>