<template>
  <v-card>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-card-title class="grid-close">
      <span class="pr-12">Ticket {{ info.ticketName }}</span>
      <v-btn
        icon
        color="primary"
        :loading="loading"
        right
        top
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="flex-center pt-4 flex-colum">
      <div class="wrap-qr mb-4">
        <div id="canvas" :class="{ 'blur-used': info.status == 'used' }"></div>
        <v-icon
          v-if="info.status == 'used'"
          size="50"
          class="check-used"
          color="#FF1744"

        >
          fa fa-check
        </v-icon>
      </div>
      <v-chip outlined>
        {{ info.shortId }}
      </v-chip>
    </v-card-text>
    <v-divider></v-divider>
  
    <v-card-actions class="d-flex flex-column">
  <v-btn class="save-btn mb-2" color="primary" @click="downloadQR" min-width="260">
    Descargar QR <v-icon class="ml-2" small>fa fa-download</v-icon>
  </v-btn>
  <v-btn class="save-btn px-6" color="primary" @click="simuleTransfer">
    Transferir a un amig@
    <v-icon class="ml-2" small>fa fa-share-nodes</v-icon>
  </v-btn>
</v-card-actions>
  </v-card>
</template>

<script>
import QRCodeStyling, { errorCorrectionLevels } from "qr-code-styling";
import { mapState, mapActions } from "vuex";

export default {
  name: "generate-qr",
  props: ["code", "info"],
  data() {
    return {
      loading: false,
      snackbar: false,
      snackbarText: "",
      width: 260,
      height: 260,
      type: "scv",
      backgroundColor: "#ffffff",
      dotColor: "#160818",
      dotType: "rounded",
      types: [
        { text: "Cuadrado", value: "square" },
        { text: "Redondeado", value: "rounded" },
        { text: "Extra Redondeado", value: "extra-rounded" },
        { text: "Puntos", value: "dots" },
        { text: "Classy", value: "classy" },
        { text: "Classy Redondeado", value: "classy-rounded" },
      ],
      imageSize: 0.5,
      imageMargin: 2,
      size: [0.1, 0.2, 0.3, 0.4],
      margin: [1, 2, 4, 6, 8, 10],
      cornerColor: "#160818",
      cornerBorderColor: "#160818",
      qrCode: null,
    };
  },
  computed: {
    ...mapState(["selectedBusiness", "isCompras", "user"]),
  },

  watch: {
    imageSize() {
      this.generateQr();
    },
    imagePadding() {
      this.generateQr();
    },
  },
  methods: {
    ...mapActions(["Alert_"]),
    generateQr() {
      this.loading = true;
      this.qrCode = new QRCodeStyling({
        width: this.width,
        height: this.height,
        type: "classy-rounded",
        data: `${this.code}`,
        //image: require("@/assets/O-01.png"),
        dotsOptions: {
          color: this.dotColor,
          type: this.dotType,
        },
        backgroundOptions: {
          color: this.backgroundColor,
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: this.imageMargin,
          imageSize: this.imageSize,
        },
        // cornersSquareOptions: {
        //   color: "#FF1744",
        //   type: "extra-rounded",
        // },
        // cornersDotOptions: {
        //   color: "#FF1744",
        //   type: "extra-rounded",
        // },
        qrOptions: {
          typeNumber: 0,
          mode: "Byte",
          errorCorrectionLevels: "Q",
        },
      });
      document.getElementById("canvas").innerHTML = "";
      this.qrCode.append(document.getElementById("canvas"));
      this.loading = false;
    },
    simuleTransfer() {
      this.Alert_({
        text: "Descarga el app para usar esta función",
        timeout: 2000,
        btn_closed: true,
        icon: false,
        iconType: "mdi-check",
        type: "info",
      });
    },
    download() {
      this.loading = true;
      this.qrCode.download({
        name: `imagen-qr-${this.user.name}`,
        extension: "png",
      });
      this.loading = false;
    },
    async downloadQR() {
      try {
        this.loading = true;
        await this.qrCode.download({ name: `QR-${this.info.shortId}`, extension: "png" });
        this.snackbarText = "QR descargado correctamente.";
      } catch (error) {
        console.error("Error al descargar el QR:", error);
        this.snackbarText = "Error al descargar el QR.";
      } finally {
        this.snackbar = true;
        this.loading = false;
      }
    },
  },

  mounted() {
    this.dotColor = "#160818";
    this.cornerBorderColor = "#00cdbc";
    this.generateQr();
  },
};
</script>

<style scoped>
.wrap-qr {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 10px;
  height: 280px;
  width: 280px;
  display: flex;
}
#canvas {
}
.flex-colum {
  justify-content: space-between;
  flex-direction: column;
}
.blur-used {
  filter: blur(10px);
}
.check-used {
  margin: auto;
  position: relative !important;
  z-index: 1000;
  left: calc(-50% - 25px);
}
</style>