import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

let env = process.env.NODE_ENV;

const prodConfig = {
  apiKey: "AIzaSyDwqeEX-PH5IV9pMboDUSgz0g6nWAoTTho",
  authDomain: "el-ocho.firebaseapp.com",
  databaseURL: "https://el-ocho.firebaseio.com",
  projectId: "el-ocho",
  storageBucket: "el-ocho.appspot.com",
  messagingSenderId: "770366666625",
  appId: "1:770366666625:web:a67dbcd611d3506d9b1a80",
  measurementId: "G-E000SQY661"
};

const devConfig = {
  apiKey: "AIzaSyBKuyr82-r86E5B2UWtPmTSR9xh8O-T1_M",
  authDomain: "delivery-dev-64a57.firebaseapp.com",
  databaseURL: "https://delivery-dev-64a57-default-rtdb.firebaseio.com",
  projectId: "delivery-dev-64a57",
  storageBucket: "delivery-dev-64a57.appspot.com",
  messagingSenderId: "124223460151",
  appId: "1:124223460151:web:b409008c2c48e19068c0b2",
  measurementId: "G-56SJQEBBZJ"
};

firebase.initializeApp(env == "production" ? prodConfig : devConfig);
// firebase.initializeApp(prodConfig);
// firebase.functions().useFunctionsEmulator("http://localhost:5001");

firebase.auth().languageCode = "es";

export const db = firebase.firestore();
export const fb = firebase;
