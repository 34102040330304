import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from "firebase/compat/app";
import VueFirestore from "vue-firestore";
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import './main.scss'
import "ant-design-vue/lib/transfer/style/css";
import "ant-design-vue/dist/antd.css";
import "leaflet-geosearch/assets/css/leaflet.css";
import '@fortawesome/fontawesome-free/css/all.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueFirestore);
Vue.use(firebase);
Object.defineProperty(Vue.prototype, "firebase", {
  get() {
    return this.$root.firebase;
  },
});
Vue.config.productionTip = false
Vue.config.devtools = true;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
